<template>
  <el-dialog
    :visible="open"
    :title="'上传' + myTypeText"
    width="578px"
    @close="close"
    class="dialog-vertical base-upload-dialog"
    :modal="showMask"
    append-to-body
    close-on-press-escape
    close-on-click-modal
  >
    <el-form label-width="70px"
             class="small-form"
             size="medium" @submit.native.prevent>
<!--      <el-form-item label="上传方式：" v-if="!hideUploadWay">-->
<!--        <el-radio-group v-model="uploadWay">-->
<!--          <el-radio :label="0">本地上传</el-radio>-->
<!--          <el-radio :label="1">手机上传</el-radio>-->
<!--        </el-radio-group>-->
<!--      </el-form-item>-->
      <template v-if="!uploadWay">
        <el-form-item v-if="folders.length" label="所在分组：">
          <el-select v-model="pid">
            <el-option v-for="(folder) in folders" :key="folder.id"
                       :value="folder.id" :label="folder.title"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="'上传' + myTypeText + '：'">
          <el-upload v-if="!status"
                     class="upload-area"
                     ref="upload"
                     drag
                     action="#"
                     multiple
                     :file-list="fileList"
                     :on-remove="handleRemove"
                     :on-change="handleChange"
                     :before-upload="beforeUpload"
                     :http-request="customUpload"
                     :auto-upload="false"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
          <div v-else class="loading">
            <el-progress v-if="!proError" type="circle" :percentage="percentage" :color="colors"></el-progress>
            <el-progress v-else type="circle" :percentage="percentage" status="exception"></el-progress>
            <!--            <div class="info">-->
            <!--              <span v-if="!proError" class="info">上传中...</span>-->
            <!--            </div>-->
          </div>
          <slot v-if="status"></slot>
        </el-form-item>
      </template>
    </el-form>
    <!-- 扫码 -->
    <div class="media-phone-upload-scan" v-if="uploadWay">
      <div class="upload-code">
        <el-image v-if="code" class="scan-code" :src="code" fit="contain"></el-image>
      </div>
      <div class="scan-tip">
        <span>扫一扫二维码，快速上传手机图片 </span>
        <el-button type="text" size="medium" @click="onRefreshCode">刷新</el-button>
      </div>
    </div>
    <template #footer>
      <template v-if="!uploadWay">
        <el-button size="small" @click="close">取 消</el-button>
        <el-button v-if="status && proError" type="primary" size="small" @click="retry">重 试</el-button>
        <el-button v-if="!proError" type="primary" size="small"
                   :loading="loading" @click="upload">{{loading ? '上传中':'上 传'}}
        </el-button>
      </template>
    </template>
  </el-dialog>
</template>
<script>
  export default {
    props: {
      // 是否显示 v-model
      open: {
        type: Boolean,
        required: true,
      },
      // 上传类型限制
      limitType: {
        type: Array,
      },
      // 是否关闭遮罩
      showMask: {
        type: Boolean,
        default: true,
      },
      code: {
        type: String,
        // required: true,
      },
      folders: {
        type: Array,
        default: () => []
      },
      hideUploadWay: {
        type: Boolean,
        default: false
      },
      // 上传
      onUpload: {
        type: Function,
        required: true,
        default: (data) => {console.log(data)}
      },
      // 上传完成后的回调，选填
      onComplete: {
        type: Function,
      },
      onRefreshCode: {
        type: Function,
        // required: true
      },
    },
    data() {
      return {
        loading: false,
        // 0：本地 1：手机
        uploadWay: 0,
        fileList: [], //没啥用，但是要
        files: [], //文件数组
        status: false, //上传状态
        percentage: 0, //上传进度条
        proError: false, //上传出错
        colors: [
          {color: "#3576FF", percentage: 90},
          {color: "#13ce66", percentage: 100},
        ],
        type: {
          image: ["jpg", "png", "jpeg", "bmp", "gif", "svg", "webp"],
          audio: ["wav", "mp3", "ogg", "aac", "webm"],
          video: ["ogg", "mpeg4", "webm", "mp4", "flv"],
          file: ["txt", "text", "pdf"],
        },
        typeText: ['图片', '音频', '视频', '文件'],
        // 文件夹
        pid: 0,
      };
    },
    computed: {
      myTypeText: function () {
        if (this.limitType) {
          if (this.limitType.length === 1) {
            let keys = Object.keys(this.type)
            let index = keys.findIndex(k => k === this.limitType[0])
            return this.typeText[index];
          }
        }
        return '文件';
      },
    },
    watch: {
      proError(val) {
        if (val) {
          clearInterval(this.upInterval);
        }
      },
      uploadWay(val) {
        if (val && !this.code) {
          this.onRefreshCode();
        }
      },
      percentage(val) {
        // console.log('progress', val)
      },
      open(val) {
        if (val) {
          // 默认显示当前文件夹
          if (this.$store.state.media.currentFolder.id > -1) {
            this.pid = this.$store.state.media.currentFolder.id;
          }
        }
      }
    },
    methods: {
      // 关闭
      close() {
        this.$emit('close')
        this.retry();
      },
      // 重置
      retry() {
        this.files = []; // 文件数组清空
        this.fileList = []; // el组件绑定数组清空
        this.percentage = 0; // 进度条归零
        this.proError = false; // 错误关闭
        this.status = false; // 上传状态还原初始值
        this.loading = false;
      },
      // 删除文件
      handleRemove(file) {
        const rmUid = file.uid;
        const index = this.fileList.findIndex((item) => item.uid === rmUid);
        this.files.splice(index, 1);
        this.fileList.splice(index, 1);
      },
      // 将文件依次传入数组
      customUpload(file) {
        // console.log(file.raw)
        this.files.push(file.raw);
        this.fileList.push(file);

        // this.doUpload(file.file)
      },
      // 上传之前，检查文件格式是否正确
      beforeUpload(file) {
        let flag = true;
        /**
         * 检测类型是否支持
         */
        if (this.limitType) {
          // Note: 文件后缀名统一用小写判断
          const fileType = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();
          // 可上传的所有类型组成的数组
          let typeArr = [];
          this.limitType.forEach((item) => {
            typeArr = typeArr.concat(this.type[item]);
          });
          if (typeArr && !typeArr.includes(fileType)) {
            flag = false;
            /* 提示不符合要求的文件 */
            this.$message.error("文件"+ file.name +"格式不正确，请选择指定的文件格式");
          }
        }
        /**
         * 检测文件大小
         */
        // const isLt2M = file.size / 1024 / 1024 < 2
        // if (!isLt2M) flag = false

        // console.log('before upload', flag)
        return flag;
      },
      handleChange(file) {
        this.customUpload(file)
      },
      // 触发上传，检测是否有选择文件，文件类型是否正确
      upload() {
        if (this.files.length) {
          /* 格式校验 */
          let valid = true;
          for (let i = 0; i < this.fileList.length; i++) {
            if (!this.beforeUpload(this.fileList[i])) {
              valid = false;
              break;
            }
          }
          if (valid) {
            this.doUpload()
          }
        } else {
          this.$message.info("上传内容为空，请选择要上传的文件！");
        }

        // this.$refs.upload.submit();
      },
      isArrayFn(value){
        if (typeof Array.isArray === "function") {
          return Array.isArray(value);
        }else{
          return Object.prototype.toString.call(value) === "[object Array]";
        }
      },
      // 上传
      doUpload(file) {
        // 显示圆形进度条
        this.status = true;
        this.loading = true;
        // 已经上传几个文件
        let fileCount = 0;
        this.onUpload({
          files: this.files,
          // files: [file],
          extra: {
            pid: this.pid
          },
          callback: (res = {}) => {
            const {code} = res;
            if (code || this.isArrayFn(res)) {
              // fileCount++;
              // 所有文件都上传完毕
              // if (fileCount === this.files.length) {
                this.percentage = 100;
                setTimeout(() => {
                this.$message.success("文件上传成功");
                this.status = false;
                // 还原
                this.retry()
                // 更新列表，关闭弹窗在此设置
                this.onComplete && this.onComplete(this.isArrayFn(res) ? res : [res.data]);
                this.loading = false;
              }, 500);
              // }
            } else {
              this.proError = true;
            }
          },
          progress: (num) => {
            // 校验进度值范围，否则element-ui报错
            this.percentage = num >= 0 && num <= 100 ? num : 0;
          }
        })
      },
    },
  };
</script>
<style lang="scss">
  .base-upload-dialog {
    .el-upload-list__item:hover {
      outline: none;
    }

    .media-phone-upload-scan {
      .upload-code {
        width: 210px;
        height: 210px;
        padding: 10px;
        margin: auto;
        border: 1px solid #d8dce6;

        .scan-code {
          width: 100%;
          height: 100%;
        }
      }

      .scan-tip {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #303133;
        text-align: center;
      }
    }

    .loading {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      width: 360px;

      .el-progress {
        margin: 0 auto;
      }

      .info {
        text-align: center;
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }
</style>
